angular
    .module('annexaApp')
    .component('annexaNotificationStatusesEvidences', {
        templateUrl: './components/reg/annexa-notification-statuses-evidences/annexa-notification-statuses-evidences.html',
        controller: ['$rootScope', 'Language', '$filter', 'AnnexaFormlyFactory', 'NotificationFactory', 'RestService', 'CommonService', '$scope', 'globalModals', 'AnnexaEntityFactory', 'ModalService', function ($rootScope, Language, $filter, AnnexaFormlyFactory, NotificationFactory, RestService, CommonService, $scope, globalModals, AnnexaEntityFactory, ModalService) {
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();

            var getLastStatus = function(notificationThird) {
                return $linq($linq(notificationThird.statuses).orderByDescending("x => x.createdDate").toArray()).firstOrDefault(undefined);
            }

            vm.thirdName = function(third, address) {
                return CommonService.getThirdCompleteNameWithAddress(third, address);
            }

            vm.lastStatus = function (notificationThird) {
                var lastStatus = getLastStatus(notificationThird);

                return lastStatus ?
                    lastStatus.status[Language.getActiveColumn()] + (lastStatus.observations ? ' (' + lastStatus.observations + ')' : '' ) :
                    $filter('translate')('global.literals.unknown');
            }

            vm.showChangeStatus = function (notificationThird) {
                if(!vm.canEdit) {
                    return false;
                }

                var lastStatus = getLastStatus(notificationThird);

                return !vm.notification.notificationType.allowManualStatusChange ?
                    false :
                    (lastStatus ? lastStatus.status.globalStatus != 'COMPLETE' : true);
            }

            vm.viewEvidence = function (document) {
            	var windowReference = window.open();
                NotificationFactory.getEvidence(document.idDocumentManager)
                    .then(function (data) {
                    	windowReference.location = data.url;
                    }).catch(function (error) {
                        console.error(error);
                        windowReference.close();
                });
            }

            vm.newEditChangeState = function (status) {
            	status.createdTime = status.createdDate;
                status.newEvidence = undefined;
                
                var modal = angular.copy(globalModals.changeStateModify);
                
                var proccesNew = function(item, form) {
                    vm.search.searchedValue = { id: item.third.id, object: item.third }
                    $rootScope.$broadcast('annexaObjectBoxSearchAdd');
                    form.close();
                }
                
                var entity = new AnnexaEntity(new Date().getTime() + '', 'modal', AnnexaEntityFactory.getEvidencesModalForm(modal, status, undefined, vm.notification, true), AnnexaEntityFactory.getEditEvidencesBoxes(status, vm.notification, $scope));
                modal.annexaFormly = entity.form;
                modal.boxes = entity.boxes;
                modal.submitModal = modal.annexaFormly.onSubmit;
                modal.alerts = [];
                entity.modal = modal;

                ModalService.showModal({
                    template: '<annexa-entity id="' + entity.id + '" entity-definition="modalObj" class="modal black-overlay" data-backdrop="false"/>',
                    controller: 'AnnexaFormlyModalController',
                    modalObj: entity
                }).then(function(modalThen) {
                    modal.modal = modalThen;
                    modal.close = modalThen.element.scope().close;
                    modal.annexaFormly.close = modalThen.element.scope().close;
                    modalThen.element.modal();
                    angular.element(".modal-title").focus();
                });
                
            }
            vm.changeState = function(notificationThird) {
            	
            	var modal = angular.copy(globalModals.changeStatusNew);
            	
                var allowedStatuses = function() {
                    var usedStatuses = $linq(notificationThird.statuses).select("x => x.status").toArray();

                    return $linq(vm.notification.notificationType.statuses).except(usedStatuses, "(x,y) => x.id == y.id").orderBy("x => x.orderView").toArray();
                }
                var proccesNew = function(item, form) {
                    vm.search.searchedValue = { id: item.third.id, object: item.third }
                    $rootScope.$broadcast('annexaObjectBoxSearchAdd');
                    form.close();
                }
                var entity = new AnnexaEntity(new Date().getTime() + '', 'modal', AnnexaEntityFactory.getEvidencesModalForm(modal, allowedStatuses(), notificationThird, vm.notification, false), AnnexaEntityFactory.getEvidencesBoxes(status));
                
                modal.annexaFormly = entity.form;
                modal.boxes = entity.boxes;
                modal.submitModal = modal.annexaFormly.onSubmit;
                modal.alerts = [];
                entity.modal = modal;

                ModalService.showModal({
                    template: '<annexa-entity id="' + entity.id + '" entity-definition="modalObj" class="modal black-overlay" data-backdrop="false"/>',
                    controller: 'AnnexaFormlyModalController',
                    modalObj: entity
                }).then(function(modalThen) {
                    modal.modal = modalThen;
                    modal.close = modalThen.element.scope().close;
                    modal.annexaFormly.close = modalThen.element.scope().close;
                    modalThen.element.modal();
                    angular.element(".modal-title").focus();
                });
            }

            vm.toggleOpen = function (item) {
                if(!item.hasOwnProperty('isOpen')) {
                    item.isOpen = false;
                }

                item.isOpen = !item.isOpen;
            }

            $scope.$on('annexaFileUploadBase64Change', function(event, args) {
                vm.modalChangeState.annexaFormly.fields[2].fieldGroup[0].data.value = args.files;
            })
        }],
        bindings: {
            notification: '=',
            canEdit: '=?'
        }
    })